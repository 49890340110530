import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../pages/Home.vue"
import Faq from "../pages/Faq.vue"
import ThankYou from "../pages/ThankYou.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { display: false  }
  },
  {
    path: '/faq',
    name: 'Faq',
    component:Faq,
    meta: { display: false  }
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component:ThankYou,
    meta: { requiresAuth: true }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
