<template>
  <v-card
    max-width="1440"
    class="elevation-0 mx-auto d-flex justify-space-between align-center pa-10"
  >
    <router-link :to="{ name: 'Home' }">
      <v-img width="100px" src="../../assets/logo.png" />
    </router-link>

    <div class="d-flex align-center">
      <router-link :to="{ name: 'Faq' }" class="me-8" v-if="$route.name !=='Faq'">
        Read FAQs
      </router-link>

      <UserSignup />
    </div>
  </v-card>
</template>
<script>
import UserSignup from "../../components/UserSignup.vue";
export default {
  components: {
    UserSignup
  }
};
</script>
