<template>
  <v-container class="text- pt-16">
    <div class="text-center">
      <p>FAQs</p>
      <p class="text-h4">Frequently asked questions</p>
      <p class="text--secondary">Have questions? we are here to help</p>
    </div>
    <!--expansin panel   -->
    <v-expansion-panels v-model="panels" elevation="0" class="my-16 pt-5" flat>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="text-subtitle-1 font-weight-medium"
        >How do I subscribe to Basic ?</v-expansion-panel-header>
        <v-expansion-panel-content
          class="text-body-2 text--lighten-2 font-weight-medium text--secondary"
        >
          <div class="d-flex">
            <span class="me-1">Click</span>
            <span class="d-inline me-1">
              <UserSignup :fromFaq="true" style="cursor: pointer;" />
            </span>
            <span>to subscribe</span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(faq, index) in faqList" :key="index">
        <v-divider class="mx-5 my-1"></v-divider>
        <v-expansion-panel-header class="text-subtitle-1 font-weight-medium">{{ faq.question }}</v-expansion-panel-header>
        <v-expansion-panel-content
          class="text-body-2 text--lighten-2 font-weight-medium text--secondary"
        >{{ faq.answer }}</v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-divider class="mx-5 my-1"></v-divider>
        <v-expansion-panel-header
          class="text-subtitle-1 font-weight-medium"
        >How can I choose my meals ?</v-expansion-panel-header>
        <v-expansion-panel-content
          class="text-body-2 text--lighten-2 font-weight-medium text--secondary"
        >
          Through our application which is available for download on
          <a
            class="text-decoration-none"
            href="https://apps.apple.com/us/app/basic-kuwait/id1614418069"
            target="_blank"
          >IOS</a>
          and
          <a
            class="text-decoration-none"
            href="https://play.google.com/store/apps/details?id=com.cocopalms.basic"
            target="_blank"
          >Android</a>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-divider class="mx-5 my-1"></v-divider>
        <v-expansion-panel-header
          class="text-subtitle-1 font-weight-medium"
        >How can I freeze my subscription?</v-expansion-panel-header>
        <v-expansion-panel-content
          class="text-body-2 text--lighten-2 font-weight-medium text--secondary"
        >
          Through our application which is available for download on
          <a
            class="text-decoration-none"
            href="https://apps.apple.com/us/app/basic-kuwait/id1614418069"
            target="_blank"
          >IOS</a>
          and
          <a
            class="text-decoration-none"
            href="https://play.google.com/store/apps/details?id=com.cocopalms.basic"
            target="_blank"
          >Android</a>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-divider class="mx-5 my-1"></v-divider>
        <v-expansion-panel-header
          class="text-subtitle-1 font-weight-medium"
        >Where can I find Basic ?</v-expansion-panel-header>
        <v-expansion-panel-content
          class="text-body-2 text--lighten-2 font-weight-medium text--secondary"
        >
          Find us at
          <a
            class="text-decoration-none"
            href="https://www.google.com/maps/place/BASIC./@29.375669,47.977208,16z/data=!4m5!3m4!1s0x3fcf84ec6a155555:0x3466f12da151136b!8m2!3d29.3768724!4d47.9775694?hl=en&shorturl=1"
            target="_blank"
          >our store</a>

          for a dine-in experience and on
          <a
            class="text-decoration-none"
            href="https://deliveroo.com.kw/en/menu/kuwait/sharq/basic-restaurant-company"
            target="_blank"
          >Deliveroo</a>
          for take out
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import UserSignup from "@/components/UserSignup.vue";
export default {
  components: {
    UserSignup
  },
  data() {
    return {
      panels: [],
      faqList: [
        {
          question: "How much weight can I expect to lose in a month ?",
          answer:
            " While this can vary from person to person due to a lot of external factors, on average we observed a 4-5 kg weight loss in our customers per month"
        },
        {
          question: " When can I expect my meal box to be delivered ?",
          answer:
            " We will deliver your meal box one day prior to it’s intended consumption date from 8am to 1pm , *if you receive your meal box at a certain time of day, expect it to be delivered around that time every day"
        },
        {
          question: "What areas do you deliver to?",
          answer:
            "We deliver to all areas in Kuwait with the exception of the following areas  : Sabah Al-Ahmad Chalet and Farm areas"
        },
        {
          question: "I’m not sure what diet type I should get, can you help?",
          answer:
            "That’s not a problem! Just subscribe to our normal meal plan and we will work with you to ensure all your dietary requirements and preferences are met"
        },
        {
          question: "What is the Keto diet?",
          answer:
            "Short for Ketogenic, a Keto diet is a new and trendy, yet effective diet based on high-fat low-carb eating influencing the body to burn fats rather than carbs"
        },
        {
          question: "Where do you source your chicken and beef from ?",
          answer:
            "Our meat and poultry are sourced with our customer in mind, Chicken - KSA ,  Beef - Australia"
        }
      ]
    };
  }
};
</script>

<style></style>
