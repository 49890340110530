import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#000000',
                green:"#648A63",
                lightGreen:"#839E87"
            },
            light: {
                primary: '#000000',
                green:"#648A63",
                lightGreen:"#839E87"
                
            },
        },
    },
})
