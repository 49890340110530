<template>
  <v-card  max-width="1340"  class="white mx-auto elevation-0 text-center  d-flex justify-center align-center">
    <div>
    <p class="text-h4">Thank you for signing up</p>
    <p class="text-h6">Download our Mobile App Now</p>

          <v-row class="mt-8 justify-center align-center ms-n8" >
            <a
              class="text-decoration-none"
              href="https://play.google.com/store/apps/details?id=com.cocopalms.basic"
              target="_blank"
            >
              <v-img
                width="150"
                :src="require('@/assets/google-play-badge.png')"
              ></v-img>
            </a>

            <a
              class="text-decoration-none ms-8"
              href="https://apps.apple.com/us/app/basic-kuwait/id1614418069"
              target="_blank"
            >
              <v-img
                width="120"
                class="mx-auto"
                :src="require('@/assets/app-store-badge.png')"
              ></v-img>
            </a>
          </v-row>
    </div>
    </v-card>
</template>
<script>
export default { };
</script>
