<template>
  <v-card
    max-width="1440"
    tile
    class="green elevation-0 mx-auto  pe-10 px-4 px-md-8 py-10"
  >
    <div class="d-flex justify-space-between green align-center">
      <router-link :to="{ name: 'Home' }">
        <v-img width="140" src="../../assets/basicYellowLogo.png" />
      </router-link>
      <div class="">
        <a class="text-decoration-none" href="tel:96594422333" target="_blank">
          <v-icon class="white--text ms-10">mdi-phone</v-icon>
        </a>

        <a
          class="text-decoration-none"
          href="https://www.instagram.com/basickuwait/"
         target="_blank"
        >
          <v-icon class="white--text ms-10">mdi-instagram</v-icon>
        </a>
        <a class="text-decoration-none" href="mailto:contact@basickw.com"
         target="_blank"
        >
          <v-icon class="white--text ms-10">mdi-email</v-icon>
        </a>
        <span class="white--text d-none d-md-inline-block ms-10"
          >Crystal Tower - Basement</span
        >
      </div>
    </div>
    <v-row class="mt-10 d-md-none" justify="center">
      <span class="white--text ">Crystal Tower - Basement</span>
    </v-row>
  </v-card>
  <!-- <app-userSignup-form class="ms-n16 ms-sm-0" v-if="$route.path == '/'" /> -->
</template>
<script>
// import UserSignup from "../../components/UserSignup.vue";
export default {
  components: {
    // "app-userSignup-form": UserSignup,
  }
};
</script>
