<template>
  <v-app id="app">
    <v-main >
      <Header />
      <router-view style="min-height:506px;"></router-view>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/core/Header.vue";
import Footer from "./components/core/Footer.vue";
export default {
  components: {
    Header,
    Footer
  },
};
</script>
<style>
html,
body {
  overflow-x: hidden;
}
</style>
