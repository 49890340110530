<template>
  <v-card max-width="1340" class="white mx-auto elevation-0">
    <v-row class="justify-center">
      <v-card
        height="500"
        class="elevation-0 d-flex justify-center align-center"
      >
        <div class="text-center text-md-left ">
          <v-card
            max-width="350"
            class="text-h2 elevation-0 header font-weight-bold mx-auto mx-md-0 mt-8 "
            >Customized Meal Plans for you</v-card
          >
          <v-card max-width="500" class="elevation-0 mt-8 mx-8 mx-md-0 ">
            If you are planning to lose weight, gain muscle or you just want a
            healthy lifestyle, basic is the right place.
          </v-card>
          <v-row class="mt-8 justify-center justify-md-start align-center" >
            <a
              class="text-decoration-none"
              href="https://play.google.com/store/apps/details?id=com.cocopalms.basic"
              target="_blank"
            >
              <v-img
                width="150"
                :src="require('@/assets/google-play-badge.png')"
              ></v-img>
            </a>

            <a
              class="text-decoration-none ms-8"
              href="https://apps.apple.com/us/app/basic-kuwait/id1614418069"
              target="_blank"
            >
              <v-img
                width="120"
                class=""
                :src="require('@/assets/app-store-badge.png')"
              ></v-img>
            </a>
          </v-row>
        </div>
      </v-card>
      <v-col cols="12" md="6" class="d-md-flex justify-md-end px-5">
        <v-img
          max-width="530"
          class="rounded-xl mx-auto mx-md-0"
          :src="require('@/assets/basic.jpg')"
          contain
        ></v-img>
      </v-col>
    </v-row>
    <v-divider class="mt-10"></v-divider>
    <v-card
      height="300px"
      max-width="1440"
      class="rounded-xl elevation-0 my-16 py-10 d-flex align-center"
    >
      <div class="mx-auto">
        <p class="text-center text-h5 mb-1">Trending Diet plans</p>
        <p class="text-center text-body-2 text--secondary">
          Basic provides different types of diet and plans
        </p>
        <v-row justify="center" class="mx-0">
          <v-chip
            v-for="(plan, index) in dietPlans"
            :key="index"
            outlined=""
            class="ma-2 pa-5 font-weight-bold"
            color="green"
            text-color="green"
            >{{ plan }}</v-chip
          >
        </v-row>
      </div>
    </v-card>
    <v-divider class="mb-10"></v-divider>
    <v-card max-width="1440" class="rounded-xl elevation-0 mb-16 py-10">
      <v-row class="mx-0 white--text">
        <v-col cols="12" md="6">
          <v-card width="500" class="mx-auto elevation-0 rounded-xl">
            <v-img
              height="500"
              class="rounded-xl elevation-0"
              :src="require('@/assets/basicTwo.jpg')"
              cover
            ></v-img>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex align-center justify-center justify-md-left text-center text-md-left"
        >
          <div class>
            <p class="green--text text-h4 mx-8 mx-md-0 mt-10 mt-md-0">
              Why you should choose Basic
            </p>
            <v-card class="text-body-1" width="500" elevation="0">
              <p class="black--text custom-text mx-16 mx-md-0">
                Meal plans are customized for you. If you are planning to lose
                weight, gain muscle or you just want a healthy lifestyle,
                <span class="green--text font-weight-bold"
                  >Basic is the right place.</span
                >
                <br />
                <br />
                <span class="green--text font-weight-bold"
                  >Basic will make things easier</span
                >
                from choosing your meals to counting your daily macros.
                <br />
                <br />Diet is not only about weight loss, but it also's about
                eating healthy, keeping your energy level high, and
                <span class="green--text font-weight-bold"
                  >providing your body with healthy nutrients.</span
                >
                <br />
                <br />
                <span class="green--text font-weight-bold"
                  >We are here to make your journey smooth, easy, and super
                  tasty.</span
                >
              </p>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dietPlans: [
        " Mediterranean diet",
        " Low carb diet",
        " Keto diet",
        "Vegetarian and vegan diet"
      ]
    };
  }
};
</script>

<style scoped>
.header {
  line-height: 70px !important;
}

.custom-text {
  line-height: 30px !important;
}
</style>
